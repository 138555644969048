import React, { memo, useEffect, useState } from 'react'
import { Journals, JournalsItem } from '@types'
import styles from './research.module.scss'

import { useInView } from 'react-intersection-observer'
import { Scrollbar } from 'react-scrollbars-custom'

interface ResearchProps {
  journals: Journals
}

const Research: React.FC<ResearchProps> = props => {
  const { journals } = props

  const [isOpened, setOpened] = useState(false)
  const [viewJournal, setViewJournal] = useState<JournalsItem>({} as JournalsItem)
  const getFullDate = inserted_at => {
    const date = new Date(inserted_at)
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const day = date.getDate()
    const monthIndex = date.getMonth()
    const year = date.getFullYear()

    return monthNames[monthIndex] + ' ' + day + ', ' + year
  }

  const selectJournal = journal => {
    setOpened(true)
    setViewJournal(journal)
  }

  const [topRef, topRefInView] = useInView({
    triggerOnce: false,
    rootMargin: '0px 0px',
    threshold: 0.2
  })

  const [bottomRef, bottomRefInView] = useInView({
    triggerOnce: false,
    rootMargin: '0px 0px',
    threshold: 0.2
  })

  const [bottomOlderRef, bottomOlderRefInView] = useInView({
    triggerOnce: false,
    rootMargin: '0px 0px',
    threshold: 0.2
  })

  useEffect(() => {
    const currentJournal = journals && journals[1]
    if (currentJournal) {
      setViewJournal(currentJournal)
    }
  }, [journals])

  return (
    <div className={styles.research + ' news-slider-scroll'} data-news-slider-scroll={true}>
      <div className={styles.researchContainer}>
        <div className={styles.researchWrapper}>
          <div className={styles.researchList}>
            <div className={styles.researchTitle}>
              Research <span>Journal Publications</span>
            </div>
            <hr />
            <div className={styles.researchListTitle}>Featured Works</div>
            {journals?.map((journal: JournalsItem) => {
              const { id, title, date, inserted_at, link, source } = journal

              return (
                <div
                  onClick={() => selectJournal(journal)}
                  key={id}
                  className={journal === viewJournal ? styles.researchListItemActive : styles.researchListItem}
                >
                  {source && (
                    <div className={styles.researchListItemHeader}>
                      <a href={link} target="_blank">
                        <div className={styles.researchListItemSource}>{source}</div>
                      </a>
                    </div>
                  )}
                  <div className={styles.researchListItemTitle}>{title}</div>
                  <div className={styles.researchListItemDate}>
                    {date ? getFullDate(date) : getFullDate(inserted_at)}
                  </div>
                </div>
              )
            })}
            <div ref={bottomOlderRef} />
          </div>
          <div className={styles.scrollForOlder}>
            <div className={styles.scrollForOlderText}>Older</div>
            <svg
              className={styles.scrollForOlderDown}
              width="18"
              height="11"
              viewBox="0 0 18 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 2L9 9L16 2"
                stroke={bottomOlderRefInView ? '#8C9CAD' : '#FFC469'}
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
        <div className={styles.researchPreview}>
          <Scrollbar
            className={styles.ScrollbarsCustom}
            trackYProps={{
              renderer: props => {
                const { elementRef, ...restProps } = props
                return <div {...restProps} ref={elementRef} className={styles.ScrollbarsCustomTrack} />
              }
            }}
            thumbYProps={{
              renderer: props => {
                const { elementRef, ...restProps } = props
                return <div {...restProps} ref={elementRef} className={styles.ScrollbarsCustomThumb} />
              }
            }}
            contentProps={{
              renderer: props => {
                const { elementRef, ...restProps } = props
                return <span {...restProps} ref={elementRef} className={styles.ScrollbarsCustomContent} />
              }
            }}
          >
            <div ref={topRef} />
            <div className={styles.researchPreviewHeader}>
              <div className={styles.researchPreviewDate}>
                {viewJournal.date ? getFullDate(viewJournal.date) : getFullDate(viewJournal.inserted_at)}
              </div>
              <div className={styles.researchPreviewSource}>{viewJournal.source}</div>
            </div>
            <div className={styles.researchPreviewTitle}>{viewJournal?.title}</div>
            <div className={styles.researchPreviewText}>
              <div className={styles.researchPreviewSubtitle}>Abstract</div>
              <div dangerouslySetInnerHTML={{ __html: viewJournal?.abstract }} />
            </div>
            <div ref={bottomRef} />
            <a className={styles.researchPreviewFullLink} href={viewJournal.link} target="_blank">
              For Full Article {viewJournal.link}
            </a>
            {/* <a target="_blank" href={viewJournal?.link} className={styles.researchPreviewFull}>
              FULL TEXT ARTICLE
            </a> */}
          </Scrollbar>
          <div className={styles.scrollForMore}>
            <svg
              className={styles.scrollForMoreUp}
              width="18"
              height="11"
              viewBox="0 0 18 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 9L9 2L2 9"
                stroke={topRefInView ? '#8C9CAD' : '#FFC469'}
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div className={styles.scrollForMoreText}>Scroll For More</div>
            <svg
              className={styles.scrollForMoreDown}
              width="18"
              height="11"
              viewBox="0 0 18 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 2L9 9L16 2"
                stroke={bottomRefInView ? '#8C9CAD' : '#FFC469'}
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>

        {isOpened && (
          <div className={styles.researchPreviewMobile}>
            <div className={styles.researchPreviewClose} onClick={() => setOpened(false)}>
              <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="44" height="44" fill="#140B26" />
                <path
                  d="M22 34C28.6274 34 34 28.6274 34 22C34 15.3726 28.6274 10 22 10C15.3726 10 10 15.3726 10 22C10 28.6274 15.3726 34 22 34Z"
                  stroke="#FFC469"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="bevel"
                />
                <path
                  d="M26.2431 26.2431L17.7578 17.7578"
                  stroke="#FAF9F9"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="bevel"
                />
                <path
                  d="M26.2422 17.7578L17.7569 26.2431"
                  stroke="#FAF9F9"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="bevel"
                />
              </svg>
            </div>
            <div className={styles.researchPreviewHeader}>
              <div className={styles.researchPreviewDate}>
                {viewJournal.date ? getFullDate(viewJournal.date) : getFullDate(viewJournal.inserted_at)}
              </div>
              <div className={styles.researchPreviewSource}>{viewJournal.source}</div>
            </div>
            <div className={styles.researchPreviewTitle}>{viewJournal?.title}</div>
            <div className={styles.researchPreviewText}>
              <div className={styles.researchPreviewSubtitle}>Abstract</div>
              {viewJournal?.abstract}
            </div>
            <div ref={bottomRef} />
            <a className={styles.researchPreviewFullLink} href={viewJournal.link} target="_blank">
              For Full Article {viewJournal.link}
            </a>
          </div>
        )}
      </div>
    </div>
  )
}

export default memo(Research)
