import { navigate, Link } from 'gatsby'
import React, { useEffect, memo } from 'react'
import { useLocation } from '@reach/router'
import { Books, Podcasts, Videos } from '@types'
import { useWindowSize } from '@hooks'
import NewsMediaListenPage from './listen'
import NewsMediaReadPage from './read'
import NewsMediaWatchPage from './watch'
import styles from './media.module.scss'

interface MediaPageProps {
  videos: Videos
  books: Books
  podcasts: Podcasts
}

const MediaPage: React.FC<MediaPageProps> = props => {
  const { videos, books, podcasts } = props

  const location = useLocation()
  const { width } = useWindowSize()

  const isMediaListenPage = location.hash.includes('#media-listen')
  const isMediaReadPage = location.hash.includes('#media-read')
  const isMediaWatchPage = location.hash.includes('#media-watch')

  const isMediaPage = isMediaListenPage || isMediaReadPage || isMediaWatchPage

  useEffect(() => {
    /* Changes hash to render news list page if no page was specified */
    // if (!isMediaPage) navigate('/news#media-watch')
  }, [])

  const renderMediaPage = () => {
    if (isMediaReadPage) return <NewsMediaReadPage books={books} />
    else if (isMediaListenPage) return <NewsMediaListenPage podcasts={podcasts} />
    else return <NewsMediaWatchPage videos={videos} />
  }

  return (
    <div
      style={{ display: 'flex', flexDirection: width <= 768 ? 'column' : 'row', overflow: 'scroll', height: '100%' }}
    >
      <div>
        {width <= 768 ? (
          <div className={styles.mobileMediaTitle}>
            Media <span> Watch.Listen.Read</span>
          </div>
        ) : null}
        <div className={styles.media}>
          <div className={styles.mediaNavigation}>
            <div className={styles.mediaNavigationList}>
              <Link
                className={isMediaWatchPage ? styles.mediaNavigationItemActive : styles.mediaNavigationItem}
                to="/news#media-watch"
              >
                Watch
              </Link>
              <Link
                className={isMediaListenPage ? styles.mediaNavigationItemActive : styles.mediaNavigationItem}
                to="/news#media-listen"
              >
                Listen
              </Link>
              <Link
                className={isMediaReadPage ? styles.mediaNavigationItemActive : styles.mediaNavigationItem}
                to="/news#media-read"
              >
                Read
              </Link>
            </div>
          </div>
        </div>
      </div>
      {renderMediaPage()}
    </div>
  )
}

export default memo(MediaPage)
