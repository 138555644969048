import React, { memo, useRef } from 'react'
import { Podcasts, PodcastItem } from '@types'
import { MediaScrollButtons } from '@components'
import { useInView } from 'react-intersection-observer'

import styles from './media-listen.module.scss'

interface NewsListenProps {
  podcasts: Podcasts
}
const NewsListen: React.FC<NewsListenProps> = props => {
  const { podcasts } = props

  const scroller = useRef(null)
  const [leftRef, leftRefInView] = useInView({ triggerOnce: false, rootMargin: '0px 0px', threshold: 0.2 })
  const [rightRef, rightRefInView] = useInView({ triggerOnce: false, rootMargin: '0px 0px', threshold: 0.2 })

  return (
    <div className={styles.mediaListen}>
      <div className={styles.mediaHeader}>
        <h2 className={styles.mediaHeaderTitle}>
          Featured <span>Listen</span>
        </h2>
        <MediaScrollButtons leftInView={leftRefInView} rightInView={rightRefInView} scroller={scroller} />
      </div>
      <div
        ref={scroller}
        className={styles.mediaPodcastContainer + ' news-slider-scroll'}
        data-news-slider-scroll={true}
      >
        {podcasts?.map((podcast: PodcastItem, index: Number) => {
          const { id, title, link, platform, avatar } = podcast
          return (
            <a key={id} className={styles.mediaPodcastItem} target="_blank" href={link}>
              {index === 0 && <div ref={leftRef} />}
              {index === podcasts.length - 1 && <div ref={rightRef} />}
              <img src={`https://palosanto.vc${avatar}`} alt="" />
              <div className={styles.mediaPodcastTextWrapper}>
                <div className={styles.mediaPodcastSource}>{platform}</div>
                <div className={styles.mediaPodcastTitle}>{title}</div>
              </div>
            </a>
          )
        })}
      </div>
    </div>
  )
}

export default memo(NewsListen)
