import { navigate, Link } from 'gatsby'
import React, { memo, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { RouteComponentProps, useLocation, useMatch } from '@reach/router'
import {
  news as graphqlNews,
  videos as graphqlVideos,
  books as graphqlBooks,
  podcasts as graphqlPodcasts,
  partners as graphqlPartners,
  partnerNews as graphqlFeaturedPartnerNews,
  journals as graphqlJournals
} from '@graphql'
import { useWindowSize } from '@hooks'
import { News, Videos, Books, Podcasts, Journals, Partners, FeaturedPartnerNews } from '@types'
import { Footer, Header, NewsHero, NewsSlider, NewsTabsNavigation } from '@components'
import NewsCoveragePage from './coverage'
import NewsMediaPage from './media'
import NewsListPage from './news-list'
import NewsResearchPage from './research'
import { NewsMobile } from '@layouts'
import { Helmet } from 'react-helmet'

import styles from './news.module.scss'

interface NewsProps extends RouteComponentProps {}

const NewsPage: React.FC<NewsProps> = props => {
  const location = useLocation()
  const { width } = useWindowSize()
  const isMobile = width <= 768
  const [isDataLoaded, setDataLoaded] = useState(false)
  const [isAdditionalBlockVisible, setAdditionalBlockVisible] = useState(true)

  const isMobilePage = isMobile && !location.hash.includes('/news')

  const isMediaPage = location.hash.includes('#media')
  const isCoveragePage = location.hash.includes('#coverage')
  const isNewsListPage = location.hash.includes('#list')
  const isResearchPage = location.hash.includes('#research')
  const isChosenPage = isMediaPage || isCoveragePage || isNewsListPage || isResearchPage

  const { data: { res: partners } = {}, loading: partnersLoading } = useQuery<{ res: Partners }>(
    graphqlPartners.FetchPartners,
    { variables: { type: 'COVERAGE' } }
  )

  const { data: { res: partnerNews } = {}, loading: partnerNewsLoading } = useQuery<{ res: FeaturedPartnerNews }>(
    graphqlFeaturedPartnerNews.FetchPartnerNews
  )

  const { data: { res: news } = {}, loading: newsLoading } = useQuery<{ res: News }>(graphqlNews.FetchAll, {
    variables: { type: 'NEWS_FEATURED' }
  })

  const { data: { res: videos } = {}, loading: videosLoading } = useQuery<{ res: Videos }>(graphqlVideos.FetchVideos)
  const { data: { res: books } = {}, loading: booksLoading } = useQuery<{ res: Books }>(graphqlBooks.FetchBooks)
  const { data: { res: podcasts } = {}, loading: podcastsLoading } = useQuery<{ res: Podcasts }>(
    graphqlPodcasts.FetchPodcasts
  )
  const { data: { res: journals } = {}, loading: journalsLoading } = useQuery<{ res: Journals }>(
    graphqlJournals.FetchJournals
  )

  const isDataLoading =
    newsLoading ||
    videosLoading ||
    booksLoading ||
    podcastsLoading ||
    journalsLoading ||
    partnersLoading ||
    partnerNewsLoading

  useEffect(() => {
    if (!isDataLoading) {
      setDataLoaded(true)
    }
  }, [isDataLoading])

  useEffect(() => {
    if (isDataLoaded) {
      handleNewsNavigate(location.hash.slice(1, location.hash.length))
    }
  }, [isChosenPage])

  const renderChildren = () => {
    if (isDataLoading) {
      return <div>Loading...</div>
    }

    if (isMobilePage) return <NewsMobile />
    if (isCoveragePage) return <NewsCoveragePage partners={partners!} />
    else if (isNewsListPage) return <NewsListPage news={news!} />
    else if (isResearchPage) return <NewsResearchPage journals={journals!} />
    else if (isMediaPage) return <NewsMediaPage books={books!} videos={videos!} podcasts={podcasts!} />
  }

  const handleNewsNavigate = (anchor: string, state?: any) => {
    navigate('/news#' + anchor, { state })
    const anchorEl = document.getElementById(anchor)
    anchorEl?.scrollIntoView()
  }

  // useEffect(() => {
  //   /* Changes hash to render news list page if no page was specified */
  //   if (!location.hash && !isMobile) navigate('/news#list')
  //   isDataLoaded
  // }, [location.hash])

  // useEffect(() => {
  //   if (isDataLoaded) {
  //     setTimeout(() => {
  //       handleNewsNavigate(location.hash.slice(1, location.hash.length))
  //     }, 300)
  //   }
  // }, [isDataLoaded])

  const renderNewsPages = () => {
    return (
      <>
        {!isDataLoading && (
          <NewsSlider
            isDataLoading={isDataLoading}
            sendHeaderHiddenToParent={value => setTimeout(setAdditionalBlockVisible(value), 100)}
          >
            <div className={styles.main}>
              <NewsTabsNavigation />
              {renderChildren()}
            </div>
          </NewsSlider>
        )}
        {!isMobile && !isDataLoading && isAdditionalBlockVisible && <div style={{ height: '100vh' }}></div>}
      </>
    )
  }

  return (
    <section>
      <Helmet>
        <title>Palo Santo | News</title>
        <meta property="og:url" content="https://palosanto.vc/" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Palo Santo" />
        <meta
          property="og:description"
          content="Leading psychedelic therapeutics investment fund dedicated to increasing the global supply of clinically effective and accessible healthcare solutions"
        />
        <meta property="og:image" content="/assets/images/social.png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet>
      <Header type="light" news={true} />
      {isDataLoading && <div style={{ background: '#140B26', height: '100vh' }}></div>}
      {!isDataLoading && <NewsHero news={news} partnerNews={partnerNews} />}
      {isChosenPage && (
        <>
          <div id={'list'} />
          <div id={'coverage'} />
          <div id={'media-watch'} />
          <div id={'media-read'} />
          <div id={'media-listen'} />
          <div id={'research'} />
        </>
      )}
      {(isChosenPage || isMobilePage) && renderNewsPages()}
      <Footer />
    </section>
  )
}

export default memo(NewsPage)
